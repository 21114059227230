import { ptBR } from "@/i18n/pt-br";
import { MenuItemType } from "@/types/MenuTypes";

const message = ptBR;

export const menuItems: MenuItemType[] = [
    {
        title: message.menu_embarque,
        children: [
            {
                title: message.sub_menu_embarque_mapa_ope,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/embarque/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_embarque_listagem,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/embarque/listagem.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_embarque_conexoes,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/embarque/conexoes.html`,
                subMenuGroupId: 3,
            },
        ],
    },
    {
        title: message.menu_carga,
        children: [
            {
                title: message.sub_menu_carga_gestao,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/carga/gestao/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_carga_listagem,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/carga/index.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_carga_cotacao,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/carga/cotacao/index-cotacao.html`,
                subMenuGroupId: 3,
            },
        ],
    },
    {
        title: message.menu_cadastro,
        children: [
            {
                title: message.sub_menu_cadastro_parceiro,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/parceiro/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_cadastro_motorista,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/motorista/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_cadastro_veiculo,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/veiculo/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_cadastro_filial,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/filial/index.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_cadastro_proprietario,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/veiculo/administracao-proprietarios/index.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_cadastro_referencia,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/motorista/referencias/referenciaTransportadora.html`,
                subMenuGroupId: 2,
            },
        ],
    },
    {
        title: message.menu_fiscal,
        children: [
            {
                title: message.sub_menu_fiscal_ct_e,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/fiscal/cte/ctes.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_fiscal_mdf_e,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/fiscal/mdfes.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_fiscal_nfe_recebidas,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/fiscal/nfes.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_fiscal_nf_entrada,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/fiscal/nfentrada/nfs.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_fiscal_relatorio_nf,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/fiscal/nfentrada/report.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_fiscal_contabilidade,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/fiscal/contabilidade/index.html`,
                subMenuGroupId: 4,
            },
        ],
    },
    {
        title: message.menu_financeiro,
        children: [
            {
                title: message.sub_menu_financeiro_recebiveis,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/recebiveis/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_financeiro_faturas,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/faturas/faturas.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_financeiro_analise_credito,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/analiseCredito.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_financeiro_gastos,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/gastos/gastos.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_financeiro_rel_gastos,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/gastos/report.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_financeiro_conta_bancaria,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/contas.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_financeiro_relatorio,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/report.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_financeiro_demonstrativo,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/dashboards/dashboardFinanceiro.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_financeiro_fluxo_caixa,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/fluxoCaixa.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_financeiro_conciliacao_bancaria,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/conciliacao.html`,
                subMenuGroupId: 4,
            },
            {
                title: message.sub_menu_financeiro_conciliacao_ofx,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/financeiro/conciliacaoOFX.html`,
                subMenuGroupId: 4,
            },
        ],
    },
    {
        title: message.menu_gestao,
        children: [
            {
                title: message.sub_menu_gestao_dashboard_embarques,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/dashboards/dashboardEmbarques.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_gestao_dashboard_csc,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/dashboards/dashboardCSC.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_gestao_dashboard_logistica,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/dashboards/dashboardLogistica.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_gestao_relatorio_metricas,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/metricas.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_gestao_indice_atendimento,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/indiceAtendimento.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_gestao_planilhas,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/planilhas/index.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_gestao_relatorio_csc,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/relatoriosCSC/index.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_gestao_relatorio_logistica,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/relatoriosLogistica/index.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_gestao_relatorio_produto,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/report/whatsapp/index.html`,
                subMenuGroupId: 3,
            },
        ],
    },
    {
        title: message.menu_qualidade,
        children: [
            {
                title: message.sub_menu_qualidade_patrimonio,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/qualidade/patrimonio/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_qualidade_pontos_melhoria,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/qualidade/pontosMelhoria/index.html`,
                subMenuGroupId: 1,
            },
        ],
    },
    {
        title: message.menu_frota,
        children: [
            {
                title: message.sub_menu_frota_proximas_viagens,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/proximaViagem/index.html`,
                subMenuGroupId: 1,
            },
            {
                title: message.sub_menu_frota_motoristas,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/cadastro/motorista.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_frota_veiculos,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/cadastro/veiculo.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_frota_conjuntos,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/conjuntos.html`,
                subMenuGroupId: 2,
            },
            {
                title: message.sub_menu_frota_cadastro_itens,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/estoque/cadastroItens.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_frota_almoxarifados,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/estoque/almoxarifado.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_frota_itens,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/estoque/itens.html`,
                subMenuGroupId: 3,
            },
            {
                title: message.sub_menu_frota_infracoes,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/infracoes.html`,
                subMenuGroupId: 4,
            },
            {
                title: message.sub_menu_frota_relatorio,
                route: `${process.env.NEXT_PUBLIC_CORE_URL}/frota/relatorios/index.html`,
                subMenuGroupId: 4,
            },
        ],
    },
];
