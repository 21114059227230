import { format, isSameDay, parse } from "date-fns";

export const formatarDataCurta = (dataHora: string): string => {
    const horaRegex = /^\d{2}:\d{2}$/;
    if (horaRegex.test(dataHora)) {
        return dataHora;
    }
    const formatoDataHora = "dd/MM/yyyy HH:mm:ss.SSS";
    const dataHoraEmDate = parse(dataHora, formatoDataHora, new Date());
    const dataAtual = new Date();

    if (isSameDay(dataHoraEmDate, dataAtual)) {
        return format(dataHoraEmDate, "HH:mm");
    }
    return format(dataHoraEmDate, "dd/MM/yyyy");
};
